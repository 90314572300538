import * as React from 'react'
import { Box, BoxProps } from '@walltowall/calico'

import { ReactComponent as AssetChevronRightSVG } from '../assets/chevron-right.svg'
import { ReactComponent as AssetChevronLeftSVG } from '../assets/chevron-left.svg'
import { ReactComponent as AssetChevronDownSVG } from '../assets/chevron-down.svg'
import { ReactComponent as Asset2020SVG } from '../assets/2020.svg'
import { ReactComponent as AssetWWLogoSVG } from '../assets/ww-logo.svg'
import { ReactComponent as AssetShareSVG } from '../assets/share.svg'
import { ReactComponent as AssetBackgroundsSVG } from '../assets/backgrounds.svg'
import { ReactComponent as AssetOkSVG } from '../assets/ok.svg'
import { ReactComponent as AssetIconInstagramSVG } from '../assets/icon-instagram.svg'
import { ReactComponent as AssetIconFacebookSVG } from '../assets/icon-facebook.svg'
import { ReactComponent as AssetIconTwitterSVG } from '../assets/icon-twitter.svg'
import { ReactComponent as AssetIconDownloadSVG } from '../assets/icon-download.svg'
import { ReactComponent as AssetIconMailSVG } from '../assets/icon-mail.svg'
import { ReactComponent as AssetSocialDistanceSVG } from '../assets/social-distance.svg'
import { XIcon } from './XIcon'

const variants = {
  chevronRight: AssetChevronRightSVG,
  chevronLeft: AssetChevronLeftSVG,
  chevronDown: AssetChevronDownSVG,
  twentyTwenty: Asset2020SVG,
  wallToWall: AssetWWLogoSVG,
  share: AssetShareSVG,
  backgrounds: AssetBackgroundsSVG,
  ok: AssetOkSVG,
  instagram: AssetIconInstagramSVG,
  facebook: AssetIconFacebookSVG,
  twitter: AssetIconTwitterSVG,
  download: AssetIconDownloadSVG,
  mail: AssetIconMailSVG,
  socialDistance: AssetSocialDistanceSVG,
  x: XIcon,
}

type Props = {
  variant: keyof typeof variants
} & BoxProps<'svg'>

export const SVG = ({ variant, ...props }: Props) => {
  const Comp = variants[variant]

  return <Box as={Comp} {...props} />
}
