import * as React from 'react'
import { Box, BoxProps } from '@walltowall/calico'
import clsx from 'clsx'

import { useCommonStyles } from '../hooks/useCommonStyles'

import { Icon } from './Icon'

const variants = {
  left: {
    icon: 'chevronLeft',
    marginLeft: -1,
    width: ['1.5rem', '2.5rem', null, '3.3125rem'],
    height: ['3.125rem', '2.5rem', null, '3.3125rem'],
  },
  leftSquare: {
    icon: 'chevronLeft',
    marginLeft: -1,
    width: ['2rem', '2.5rem', null, '3.3125rem'],
    height: ['2rem', '2.5rem', null, '3.3125rem'],
  },
  right: {
    icon: 'chevronRight',
    marginLeft: 1,
    width: ['1.5rem', '2.5rem', null, '3.3125rem'],
    height: ['3.125rem', '2.5rem', null, '3.3125rem'],
  },
} as const

type ChevronButtonProps = {
  variant: keyof typeof variants
} & BoxProps<'button'>

export const ChevronButton = ({
  variant: variantName,
  className,
  ...props
}: ChevronButtonProps) => {
  const variant = variants[variantName]

  const commonStyles = useCommonStyles()

  return (
    <Box
      as="button"
      {...props}
      className={clsx(
        commonStyles.shadow[2],
        commonStyles.focusYMovement,
        className,
      )}
      styles={{
        backgroundColor: 'black',
        color: 'white',
        cursor: 'pointer',
        width: variant.width,
        height: variant.height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transitionProperty: 'transform',
        transitionDuration: 'normal',
        transitionTimingFunction: 'easeOut',
        ...props.styles,
      }}
    >
      <Icon
        name={variant.icon}
        styles={{ width: '5/12', marginLeft: variant.marginLeft }}
      />
    </Box>
  )
}
