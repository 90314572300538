import * as React from 'react'
import { Box, BoxProps } from '@walltowall/calico'

import { ReactComponent as AssetIconSmileSVG } from '../assets/icon-smile.svg'
import { ReactComponent as AssetIconAbstractSVG } from '../assets/icon-abstract.svg'
import { ReactComponent as AssetIconCakeSVG } from '../assets/icon-cake.svg'
import { ReactComponent as AssetIconChatSVG } from '../assets/icon-chat.svg'
import { ReactComponent as AssetIconSymbolsSVG } from '../assets/icon-symbols.svg'
import { ReactComponent as AssetIconPlusSVG } from '../assets/icon-plus.svg'
import { ReactComponent as AssetIconChevronRightSVG } from '../assets/icon-chevron-right.svg'
import { ReactComponent as AssetIconChevronLeftSVG } from '../assets/icon-chevron-left.svg'
import { ReactComponent as AssetIconChevronDownSVG } from '../assets/icon-chevron-down.svg'
import { ReactComponent as AssetIconShareSVG } from '../assets/icon-share.svg'
import { ReactComponent as AssetIconTwitterSVG } from '../assets/icon-twitter-alone.svg'
import { ReactComponent as AssetIconInstagramSVG } from '../assets/icon-instagram-alone.svg'
import { ReactComponent as AssetIconFacebookSVG } from '../assets/icon-facebook-alone.svg'

const icons = {
  smile: AssetIconSmileSVG,
  abstract: AssetIconAbstractSVG,
  cake: AssetIconCakeSVG,
  chat: AssetIconChatSVG,
  symbols: AssetIconSymbolsSVG,
  plus: AssetIconPlusSVG,
  chevronLeft: AssetIconChevronLeftSVG,
  chevronRight: AssetIconChevronRightSVG,
  chevronDown: AssetIconChevronDownSVG,
  share: AssetIconShareSVG,
  twitter: AssetIconTwitterSVG,
  instagram: AssetIconInstagramSVG,
  facebook: AssetIconFacebookSVG,
}

export type IconProps = {
  name: keyof typeof icons
} & BoxProps<'svg'>

export const Icon = ({ name, ...props }: IconProps) => {
  const Comp = icons[name]

  return <Box as={Comp} {...props} />
}
