import { atom, selector } from 'recoil'

import { BASE_CARD_SIZE_PX } from './constants'
import { Sticker } from './types'

export const cardSize = atom({
  key: 'CardBounds',
  default: BASE_CARD_SIZE_PX,
})

export const scale = selector({
  key: 'Scale',
  get: ({ get }) => get(cardSize) / BASE_CARD_SIZE_PX,
})

export const cardStickers = atom<Sticker[]>({
  key: 'CardStickers',
  default: [],
})

interface ShareModalPayload {
  isLoading?: boolean
  previewImageUrl?: string
  ogImageUrl?: string
}

interface ModalAtom {
  isOpen: boolean
  type: 'intro' | 'instructions' | 'share' | 'shareInstagram' | 'social'
  payload?: ShareModalPayload
}

export const modal = atom<ModalAtom>({
  key: 'Modal',
  default: {
    isOpen: true,
    type: 'intro',
  },
})

export const cardImageIndex = atom<number>({
  key: 'CardImageIndex',
  default: 0,
})

export const screenStickers = atom<Sticker[]>({
  key: 'ScreenStickers',
  default: [],
})
