import * as React from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import { Box } from '@walltowall/calico'
import { useRecoilState } from 'recoil'

import { useCommonStyles } from '../hooks/useCommonStyles'

import { SVG } from './SVG'
import { Text } from './Text'
import { KraftBg } from './Modal'
import { Link } from './Link'
import { ChevronButton } from './ChevronButton'

import * as state from '../state'

export const ShareInstagramModal = () => {
  const [modal, setModal] = useRecoilState(state.modal)
  const commonStyles = useCommonStyles()

  const goToShareModal = () =>
    setModal({
      isOpen: true,
      type: 'share',
      payload: {
        isLoading: false,
        previewImageUrl: modal.payload?.previewImageUrl,
        ogImageUrl: modal.payload?.ogImageUrl,
      },
    })

  return (
    <Box
      styles={{
        padding: [8, 12],
        margin: [4, null, 6],
        position: 'relative',
        borderRadius: 10,
        maxWidth: 'small',
        width: 'full',
        color: 'black',
      }}
    >
      <KraftBg />

      <Box
        styles={{ position: 'absolute' }}
        style={{ right: -20, bottom: -30 }}
      >
        <VisuallyHidden>Social Distance Friendly</VisuallyHidden>
        <SVG variant="socialDistance" style={{ width: 100 }} />
      </Box>

      <Box
        styles={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box styles={{ display: 'grid', gap: 6, justifyItems: 'center' }}>
          <Text
            variant="sansCond18-24"
            styles={{
              textTransform: 'uppercase',
              letterSpacing: '.03em',
              textAlign: 'center',
            }}
          >
            Share to Instagram
          </Text>

          {modal.payload?.previewImageUrl && (
            <Box
              as="img"
              width={200}
              height={200}
              style={{ width: 200, height: 200 }}
              src={modal.payload.previewImageUrl}
              alt="Your Occasional Card."
              styles={{
                borderRadius: 10,
                borderStyle: 'solid',
                borderColor: 'black',
                borderWidth: '1px',
              }}
            />
          )}

          <Box as="ol" styles={{ display: 'grid', gap: 3 }}>
            <Text as="li" variant="sans-16-20">
              On your phone, <strong>tap and hold</strong> the image
            </Text>
            <Text as="li" variant="sans-16-20">
              Tap <strong>Add to Photos</strong> or{' '}
              <strong>Download image</strong>
            </Text>
            <Text as="li" variant="sans-16-20">
              <strong>Open Instagram</strong> and post the image
            </Text>
            <Text as="li" variant="sans-16-20">
              Tag <strong>@walltowall</strong>
            </Text>
          </Box>

          <Link href="https://instagram.com">
            <Box
              className={commonStyles.shadow[2]}
              styles={{
                display: 'block',
                paddingTop: 3,
                paddingBottom: 3,
                paddingLeft: 5,
                paddingRight: 5,
                backgroundColor: 'black',
                color: 'white',
                justifySelf: 'center',
              }}
            >
              <Text
                variant="sansCond13-15"
                styles={{ textTransform: 'uppercase' }}
              >
                Open Instagram
              </Text>
            </Box>
          </Link>
        </Box>
      </Box>

      <ChevronButton
        variant="leftSquare"
        onClick={goToShareModal}
        styles={{
          position: 'absolute',
          top: 0,
          left: 0,
          marginTop: 5,
          marginLeft: 5,
        }}
      />
    </Box>
  )
}
