import * as React from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import { Box, BoxProps } from '@walltowall/calico'
import { useRecoilState } from 'recoil'

import * as state from '../state'

import { SVG } from './SVG'
import { ShareModal } from './ShareModal'
import { IntroModal } from './IntroModal'
import { InstructionsModal } from './InstructionsModal'
import { ShareInstagramModal } from './ShareInstagramModal'
import { SocialModal } from './SocialModal'
import kraftBgUrl from '../assets/kraft-bg.jpg'

import * as styleRefs from './Modal.treat'
import { use100vh } from 'react-div-100vh'

interface OkButtonProps extends BoxProps<'button'> {
  altText: string
}

const OkButton = ({ altText, ...props }: OkButtonProps) => {
  return (
    <Box as="button" {...props}>
      <VisuallyHidden>{altText}</VisuallyHidden>
      <SVG variant="ok" style={{ width: 72 }} />
    </Box>
  )
}

export const KraftBg = () => {
  return (
    <Box
      as="img"
      styles={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: 'full',
        height: 'full',
        pointerEvents: 'none',
        objectFit: 'cover',
        borderRadius: 10,
      }}
      src={kraftBgUrl}
    />
  )
}

interface KraftBgModalWithButtonProps {
  onButtonClick?: () => void
  children: React.ReactNode
  altText: string
}

export const KraftBgWithButton = ({
  onButtonClick,
  altText,
  children,
}: KraftBgModalWithButtonProps) => {
  return (
    <Box
      className={styleRefs.shadow}
      styles={{
        paddingTop: [10, 12],
        paddingLeft: [6, 10],
        paddingRight: [6, 10],
        paddingBottom: [16, 20],
        margin: [4, null, 6],
        position: 'relative',
        borderRadius: 10,
        maxWidth: 'small',
        width: 'full',
        backgroundColor: 'brown.60',
        color: 'black',
      }}
    >
      <KraftBg />
      <Box styles={{ position: 'relative' }}>{children}</Box>

      <OkButton
        styles={{
          position: 'absolute',
          left: '50%',
          transform: 'x--50%',
        }}
        style={{ bottom: -36 }}
        onClick={onButtonClick}
        altText={altText}
      />
    </Box>
  )
}

export const Modal = () => {
  const [modal, setModal] = useRecoilState(state.modal)
  const viewportHeight = use100vh()

  return (
    <Box
      styles={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        overflow: 'auto',
        padding: 2,
      }}
      style={{ height: '100vh', maxHeight: viewportHeight ?? 0 }}
    >
      {/* Overlay */}
      <Box
        styles={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 75,
          backgroundColor: 'gray.20',
        }}
        onClick={() => setModal({ isOpen: false, type: 'instructions' })}
      />

      {modal.type === 'intro' && <IntroModal />}
      {modal.type === 'instructions' && <InstructionsModal />}
      {modal.type === 'share' && <ShareModal />}
      {modal.type === 'shareInstagram' && <ShareInstagramModal />}
      {modal.type === 'social' && <SocialModal />}
    </Box>
  )
}
