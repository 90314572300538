import React from 'react'
import clsx from 'clsx'
import { useStyles } from 'react-treat'
import { Box, BoxProps } from '@walltowall/calico'
import ConditionalWrap from 'conditional-wrap'

import * as styleRefs from './Text.treat'

const variantExtraStyles: Record<styleRefs.variations, BoxProps['styles']> = {
  sans11: {
    fontFamily: 'sans',
  },
  sans16: {
    fontFamily: 'sans',
  },
  sans18: {
    fontFamily: 'sans',
  },
  'sans-16-20': {
    fontFamily: 'sans',
  },
  'sans16-24': {
    fontFamily: 'sans',
  },
  'sansCond13-15': {
    fontFamily: 'sansCond',
    letterSpacing: '.09em',
  },
  'sansCond-20-64': {
    fontFamily: 'sansCond',
  },
  'sansCond-14-20': {
    fontFamily: 'sansCond',
  },
  sansCond18: {
    fontFamily: 'sansCond',
  },
  sansCond28: {
    fontFamily: 'sansCond',
  },
  'sansCond18-24': {
    fontFamily: 'sansCond',
  },
  'sansCond18-28': {
    fontFamily: 'sansCond',
  },
  sansExtCond24: {
    fontFamily: 'sansExtCond',
  },
} as const

type TextProps = Omit<BoxProps, 'as' | 'component'> & {
  as?: 'p' | 'span' | 'strong' | 'em' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'li'
  children?: React.ReactNode
  variant?: styleRefs.variations
  debug?: boolean
}

export const Text = ({
  as = 'p',
  variant = 'sans16',
  children,
  className,
  debug = false,
  ...props
}: TextProps) => {
  const styles = useStyles(styleRefs)

  return (
    <ConditionalWrap
      condition={debug}
      wrap={(children) => (
        <div style={{ boxShadow: '0 0 0 0.5px #f0f', marginBottom: 30 }}>
          {children}
        </div>
      )}
    >
      <Box
        as={as}
        className={clsx(styles.variants[variant], className)}
        {...props}
        styles={{
          ...variantExtraStyles[variant],
          ...props.styles,
        }}
      >
        {children}
      </Box>
    </ConditionalWrap>
  )
}

Text.variants = Object.keys(styleRefs.variants)
