import * as React from 'react'
import { Box, BoxProps } from '@walltowall/calico'

import kraftBgUrl from '../assets/kraft-bg.jpg'

type XIconProps = BoxProps<'svg'>

// This SVG needed to be manually created since the exported asset would embed
// the bg-image in base64 format (huge file size). Manually converting allows us
// to use our asset URL instead which is cached across all usages on the site.
export const XIcon = (props: XIconProps) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          id="prefix__a"
          d="M16 2l6 6-4 4 4 4-6 6-4-4-4 4-6-6 4-4-4-4 6-6 4 4z"
        />
        <mask id="prefix__b" fill="#fff">
          <use fillRule="evenodd" xlinkHref="#prefix__a" />
        </mask>
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#969696" xlinkHref="#prefix__a" />
        <image
          height={27}
          mask="url(#prefix__b)"
          width={19}
          x={2}
          y={-5}
          xlinkHref={kraftBgUrl}
          preserveAspectRatio="none"
        />
        <path
          d="M14.7.69L20.275 6l-4.2 4 4.2 4-5.575 5.31-4.2-4-4.2 4L.725 14l4.2-4-4.2-4L6.3.69l4.2 4z"
          stroke="#000"
        />
      </g>
    </Box>
  )
}
