import React from 'react'
import { useRecoilValue } from 'recoil'
import { Helmet } from 'react-helmet-async'
import { SkipNavLink } from '@reach/skip-nav'
import { Box } from '@walltowall/calico'

import { Text } from './Text'
import { DevPanel } from './DevPanel'

import * as state from '../state'
import { Modal } from './Modal'

export type LayoutProps = {
  children?: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  const modal = useRecoilValue(state.modal)

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Occasional Cards</title>
        <meta name="description" content="Occasional Cards" />
        <link rel="stylesheet" href="https://use.typekit.net/jsu2dka.css" />
      </Helmet>
      <SkipNavLink>
        <Text>Skip to content</Text>
      </SkipNavLink>
      <Box
        as="main"
        styles={{ display: 'flex', color: 'debug', overflowX: 'hidden' }}
      >
        <Box styles={{ width: 'full', position: 'relative' }}>{children}</Box>
        {modal.isOpen && <Modal />}
      </Box>
      {process.env.NODE_ENV === 'development' && <DevPanel />}
    </>
  )
}
