import * as React from 'react'
import { Box, useBoxStyles } from '@walltowall/calico'
import { useRecoilState } from 'recoil'
import { motion } from 'framer-motion'

import * as state from '../state'

import { Text } from './Text'
import { KraftBgWithButton } from './Modal'
import instructionsUrl from '../assets/instructions.png'

interface TextBubbleProps {
  heading: string
  text: string
  style?: React.CSSProperties
  delay: number
}

const TextBubble = ({ heading, text, style, delay }: TextBubbleProps) => {
  return (
    <motion.div
      className={useBoxStyles({
        borderRadius: 10,
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 4,
        paddingBottom: 4,
        backgroundColor: 'white',
        position: 'absolute',
      })}
      style={{
        maxWidth: 150,
        filter: 'drop-shadow(0 1px 0px rgba(0, 0, 0, 0.3))',
        ...style,
      }}
      initial={{ opacity: 0, scale: 0.95, y: 3 }}
      animate={{ opacity: 1, scale: 1, y: 0 }}
      transition={{ delay, type: 'spring' }}
    >
      <Text
        variant="sansCond18"
        styles={{
          letterSpacing: '.03em',
          textTransform: 'uppercase',
          marginBottom: 2,
        }}
      >
        {heading}
      </Text>
      <Text variant="sans11">{text}</Text>
    </motion.div>
  )
}

export const InstructionsModal = () => {
  const [, setModal] = useRecoilState(state.modal)

  return (
    <KraftBgWithButton
      onButtonClick={() => setModal({ isOpen: false, type: 'instructions' })}
      altText="Start building Occasional Cards!"
    >
      <Box
        styles={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box styles={{ position: 'relative' }}>
          <Box
            as="img"
            src={instructionsUrl}
            loading="lazy"
            styles={{
              width: '7/12',
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
              position: 'relative',
              objectFit: 'contain',
            }}
            style={{ maxHeight: '70vh' }}
          />
          <TextBubble
            style={{ top: '5%', left: '-5%' }}
            heading="1. Lay the foundation"
            text="Swipe to choose your background."
            delay={0.4}
          />
          <TextBubble
            style={{ bottom: '-3%', left: '-5%' }}
            heading="2. Stick ‘Em"
            text="Drag stickers to the card to create your message."
            delay={1.2}
          />
          <TextBubble
            style={{ bottom: '25%', right: '-5%' }}
            heading="3. Share"
            text="The world deserves to see."
            delay={2.0}
          />
        </Box>
      </Box>
    </KraftBgWithButton>
  )
}
