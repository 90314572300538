import * as React from 'react'
import { useRecoilState } from 'recoil'
import { Box } from '@walltowall/calico'

import { Text } from './Text'
import { KraftBgWithButton } from './Modal'

import * as state from '../state'

export const IntroModal = () => {
  const [, setModal] = useRecoilState(state.modal)

  return (
    <KraftBgWithButton
      onButtonClick={() => setModal({ isOpen: true, type: 'instructions' })}
      altText="Go to instructions."
    >
      <Box
        styles={{
          display: 'grid',
          gap: 5,
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        <Text
          variant="sans-16-20"
          styles={{
            textAlign: 'center',
            fontWeight: 'medium',
          }}
        >
          At Wall-to-Wall Studios, we like to facilitate the sharing of
          greetings for both common and unusual occasions.
        </Text>
        <Text
          variant="sans-16-20"
          styles={{
            textAlign: 'center',
            fontWeight: 'medium',
          }}
        >
          2020 made for some of the least usual occasions, and what better way
          to express how you feel about them than by assembling your very own
          message using our limited selection of time-sensitive word-stickers
          beamed in from the multitude of satellite (home) offices of
          Wall-to-Wall Studios.
        </Text>
      </Box>
    </KraftBgWithButton>
  )
}
