import * as React from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import { Box, useBoxStyles } from '@walltowall/calico'
import { useRecoilState } from 'recoil'

import {
  WW_INSTAGRAM_URL,
  WW_FACEBOOK_URL,
  WW_TWITTER_URL,
  WW_SHOP_URL,
} from '../constants'
import * as state from '../state'
import { useCommonStyles } from '../hooks/useCommonStyles'

import { SVG } from './SVG'
import { Text } from './Text'
import { KraftBg } from './Modal'
import { ChevronButton } from './ChevronButton'
import { Link } from './Link'

export const SocialModal = () => {
  const iconStyles = useBoxStyles({
    width: 'full',
    maxWidth: '3.125rem',
  })
  const commonStyles = useCommonStyles()

  const [, setModal] = useRecoilState(state.modal)
  const closeModal = () => setModal({ isOpen: false, type: 'social' })

  return (
    <Box
      styles={{
        padding: [8, 12],
        margin: [4, null, 6],
        position: 'relative',
        borderRadius: 10,
        maxWidth: 'small',
        width: 'full',
        color: 'black',
      }}
    >
      <KraftBg />

      <Box
        styles={{ position: 'absolute' }}
        style={{ right: -20, bottom: -30 }}
      >
        <VisuallyHidden>Social Distance Friendly</VisuallyHidden>
        <SVG variant="socialDistance" style={{ width: 100 }} />
      </Box>

      <Box
        styles={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box styles={{ display: 'grid', gap: 8, justifyItems: 'center' }}>
          <Text
            variant="sansCond18-24"
            styles={{
              textTransform: 'uppercase',
              letterSpacing: '.03em',
              textAlign: 'center',
            }}
          >
            Follow Wall-to-Wall Studios
          </Text>

          <Box
            styles={{
              display: 'grid',
              gridAutoFlow: 'column',
              gap: 6,
              alignContent: 'start',
            }}
          >
            <Box
              as="a"
              href={WW_INSTAGRAM_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <VisuallyHidden>Instagram</VisuallyHidden>
              <SVG variant="instagram" className={iconStyles} />
            </Box>
            <Box
              as="a"
              href={WW_FACEBOOK_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <VisuallyHidden>Facebook</VisuallyHidden>
              <SVG variant="facebook" className={iconStyles} />
            </Box>
            <Box
              as="a"
              href={WW_TWITTER_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <VisuallyHidden>Twitter</VisuallyHidden>
              <SVG variant="twitter" className={iconStyles} />
            </Box>
          </Box>

          <Link href={WW_SHOP_URL}>
            <Box
              className={commonStyles.shadow[2]}
              styles={{
                display: 'block',
                paddingTop: 3,
                paddingBottom: 3,
                paddingLeft: 5,
                paddingRight: 5,
                backgroundColor: 'black',
                color: 'white',
                justifySelf: 'center',
              }}
            >
              <Text
                variant="sansCond13-15"
                styles={{ textTransform: 'uppercase', textAlign: 'center' }}
              >
                Get the real cards at the W|W Shop
              </Text>
            </Box>
          </Link>
        </Box>
      </Box>

      <ChevronButton
        variant="leftSquare"
        onClick={closeModal}
        styles={{
          position: 'absolute',
          top: 0,
          left: 0,
          marginTop: 5,
          marginLeft: 5,
        }}
      />
    </Box>
  )
}
