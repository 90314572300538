import * as React from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import { Box, useBoxStyles } from '@walltowall/calico'
import { useRecoilState } from 'recoil'

import { useCommonStyles } from '../hooks/useCommonStyles'
import * as state from '../state'

import { SVG } from './SVG'
import { Text } from './Text'
import { KraftBg } from './Modal'
import { Link } from './Link'

function buildShareUrl(imageUrl: string) {
  const shareUrl = new URL(`${window.location.origin}/share`)
  const [, fileName] = imageUrl.split('upload/')
  shareUrl.searchParams.set('file', fileName)

  return shareUrl
}

export const ShareModal = () => {
  const iconStyles = useBoxStyles({
    width: 'full',
    maxWidth: '3.125rem',
  })
  const [modal, setModal] = useRecoilState(state.modal)

  const ogImageUrl = modal.payload?.ogImageUrl
  const previewImageUrl = modal.payload?.previewImageUrl

  const downloadUrl = modal.payload?.previewImageUrl?.replace?.(
    /.*\/image\/upload\//,
    `${window.location.origin}/download-card/`,
  )

  const directUrl = modal.payload?.previewImageUrl?.replace?.(
    /.*\/image\/upload\//,
    `${window.location.origin}/card/`,
  )

  const mailtoBody = encodeURIComponent(
    `${directUrl}\n\n---\n\nSent from https://occasionalcards.walltowall.com by Wall-to-Wall Studios\n\nAssemble your very own message using our limited selection of time-sensitive word-stickers, then share it—from at least 6 ft away.`,
  )
  const mailtoUrl = `mailto:?body=${mailtoBody}`

  const twitterUrl = (() => {
    const twitter = new URL('https://twitter.com/intent/tweet')

    if (modal.payload?.ogImageUrl) {
      const shareUrl = buildShareUrl(modal.payload.ogImageUrl)
      twitter.searchParams.set('url', shareUrl.toString())
    }

    twitter.searchParams.set('text', 'Share your somewhat heartfelt message.')

    return twitter
  })()

  const facebookUrl = (() => {
    const facebook = new URL('https://wwww.facebook.com/dialog/share')

    if (modal.payload?.ogImageUrl) {
      const shareUrl = buildShareUrl(modal.payload.ogImageUrl)
      facebook.searchParams.set('href', shareUrl.toString())
    }

    facebook.searchParams.set('app_id', '1662050167345033')
    facebook.searchParams.set('display', 'page')

    return facebook
  })()

  const openShareInstagramModal = () =>
    setModal({
      isOpen: true,
      type: 'shareInstagram',
      payload: {
        previewImageUrl: modal.payload?.previewImageUrl,
        ogImageUrl: modal.payload?.ogImageUrl,
      },
    })

  const commonStyles = useCommonStyles()

  return (
    <Box
      styles={{
        padding: [8, 12],
        margin: [4, null, 6],
        position: 'relative',
        borderRadius: 10,
        maxWidth: 'small',
        width: 'full',
        color: 'black',
      }}
    >
      <KraftBg />
      <Box
        styles={{ position: 'absolute' }}
        style={{ right: -20, bottom: -30 }}
      >
        <VisuallyHidden>Social Distance Friendly</VisuallyHidden>
        <SVG variant="socialDistance" style={{ width: 100 }} />
      </Box>

      <Box
        styles={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box styles={{ marginBottom: 3 }}>
          <Text
            variant="sansCond18-24"
            styles={{
              textTransform: 'uppercase',
              letterSpacing: '.03em',
              textAlign: 'center',
            }}
          >
            {modal.payload?.isLoading ? 'Putting together your card' : 'Share'}
          </Text>
        </Box>

        {modal.payload?.isLoading && (
          <Text
            variant="sansCond28"
            styles={{
              marginTop: 4,
              marginBottom: 8,
              textTransform: 'uppercase',
            }}
          >
            Loading...
          </Text>
        )}

        {modal.payload?.isLoading ? (
          <Text
            variant="sans18"
            styles={{ textAlign: 'center', maxWidth: '17ch' }}
          >
            Things like this can take a bit of time.
          </Text>
        ) : (
          <Text
            variant="sans18"
            styles={{ textAlign: 'center', maxWidth: '17ch', marginBottom: 5 }}
          >
            Say it don’t spray it.
          </Text>
        )}

        {!modal.payload?.isLoading && (
          <Box
            styles={{
              display: 'grid',
              gridAutoFlow: 'column',
              gap: 3,
              marginBottom: 5,
              alignContent: 'start',
            }}
          >
            <Box
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href={mailtoUrl}
            >
              <VisuallyHidden>Send an email</VisuallyHidden>
              <SVG variant="mail" className={iconStyles} />
            </Box>

            <Box as="button" onClick={openShareInstagramModal}>
              <VisuallyHidden>Share to Instagram</VisuallyHidden>
              <SVG variant="instagram" className={iconStyles} />
            </Box>

            <Box
              as="a"
              href={facebookUrl.toString()}
              target="_blank"
              rel="noopener noreferrer"
            >
              <VisuallyHidden>Share to Facebook</VisuallyHidden>
              <SVG variant="facebook" className={iconStyles} />
            </Box>
            <Box
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href={twitterUrl.toString()}
            >
              <VisuallyHidden>Share to Twitter</VisuallyHidden>
              <SVG variant="twitter" className={iconStyles} />
            </Box>
          </Box>
        )}

        {modal.payload?.previewImageUrl && (
          <Box
            as="img"
            width={250}
            style={{ width: 250 }}
            src={modal.payload.previewImageUrl}
            alt="Your Occasional Card."
            styles={{
              borderRadius: 10,
              borderStyle: 'solid',
              borderColor: 'black',
              borderWidth: '1px',
              marginBottom: 6,
            }}
          />
        )}

        {downloadUrl && (
          <Link href={downloadUrl}>
            <Box
              className={commonStyles.shadow[2]}
              styles={{
                display: 'block',
                paddingTop: 3,
                paddingBottom: 3,
                paddingLeft: 5,
                paddingRight: 5,
                backgroundColor: 'white',
                color: 'black',
                justifySelf: 'center',
              }}
            >
              <Text
                variant="sansCond13-15"
                styles={{ textTransform: 'uppercase' }}
              >
                Download your card
              </Text>
            </Box>
          </Link>
        )}
      </Box>
    </Box>
  )
}
